<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2 form-cell">
            <div class="form-group-title"><h2>宿舍信息</h2></div>
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="data-item su-box clearfix">
                        <div class="st">{{ room.supplierName }}</div>
                        <div class="su">{{ room.buildName }}{{ room.floorName }}{{ room.roomName }}室</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">负责人：</div>
                        <div class="text">{{ room.supplierFuzeren }}</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">联系方式：</div>
                        <div class="text">{{ room.tel }}</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">房型：</div>
                        <div class="text">{{ room.checkinNum }}人间</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">住宿类型：</div>
                        <div class="text">{{ roomTypeText }}</div>
                    </div>
                </el-col>
                <!-- <el-col :span="8">
                    <div class="data-item">
                        <div class="label">住宿配置：</div>
                        <div class="text">空调、架子床、柜子</div>
                    </div>
                </el-col> -->
            </el-row>
            <div class="form-group-title">
                <h2>人员信息</h2>
                <div class="btn-box">
                    <el-button type="primary" size="mini" @click="handleSelectPerson">选择人员</el-button>
                </div>
            </div>
            <div class="datatable-wrap-1">
                <el-form-item label-width="0" prop="personIdList">
                    <div class="datatable">
                        <el-table
                            :data="tableData"
                            v-loading="tableLoading"
                            element-loading-background="rgba(255, 255, 255, 0.3)"
                            border
                            style="width: 100%">
                            <el-table-column
                                label="序号"
                                align="center"
                                width="80">
                                <template slot-scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="人员姓名"
                                width="200">
                            </el-table-column>
                            <el-table-column
                                prop="numId"
                                label="身份证号"
                                width="200">
                            </el-table-column>
                            <el-table-column
                                prop="sex"
                                label="性别">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.sex == 0">男</span>
                                    <span v-if="scope.row.sex == 1">女</span>
                                    <span v-if="scope.row.sex != 0 && scope.row.sex != 1">-</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="intoDate"
                                label="入住时间">
                            </el-table-column>
                            <el-table-column
                                prop="action"
                                label="操作"
                                align="left"
                                width="100"
                                class-name="action">
                                <template slot-scope="scope">
                                    <el-button
                                        size="mini"
                                        type="danger"
                                        :disabled="!scope.row.isNew"
                                        @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
            </div>
            <el-row :gutter="20">
                <el-col :span="12" class="mix-cell">
                    <el-form-item label="入住时间：" prop="intoDate">
                        <el-date-picker
                            v-model="form.intoDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'Arrange',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    computed: {
        roomTypeText() {
            switch(String(this.room.roomType)) {
                case '1': return "男宿";
                case '2': return "女宿";
                case '3': return "夫妻房";
                case '4': return "仓库";
                case '5': return "办公室";
                case '6': return "隔离室";
                case '7': return "沃舟自用房";
                case '8': return "其他";
                default: return "-";
            }
        },
    },
    data(){
        return {
            room: {},
            tableData: [],
            tableLoading: false,
            form: {
                roomId: this.params.id,
                personIdList: [],
                intoDate: '',
            },
            rules: {
                personIdList: [
                    { required: true, message: '请选安排入住的人员', trigger: 'change' },
                    { validator: this.checkParsonList, trigger: 'change'},
                ],
                intoDate: [{required: true, message: '请选择入住时间', trigger: 'change'}],
            },
            submitting: false
        }
    },
    created() {
        this.loadData();
    },
    watch: {
        ['tableData']: {
            handler(value) {
                this.form.personIdList = value.filter(x=>x.isNew).map(x=>x.id);
                if (this.$refs.form) {
                    this.$refs.form.validateField("personIdList");
                }
            },
            immediate: true,
        }
    },
    methods: {
        ...common,
        ...verify,
        loadData() {
            let loading = this.load();
            let params = {};
            params.id = this.params.id;
            http.get('v1/build/roomDetail', {
                params: params
            }).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    this.room = data;
                    this.tableData = !data.workerList ? [] : data.workerList;
                    this.$nextTick(() => {
                        if (this.$refs.form) {
                            this.$refs.form.clearValidate("personIdList");
                        }
                    });
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let data = {};
                    data.buildId = this.form.roomId;
                    data.checkinWorkerIds = this.form.personIdList;
                    data.day = this.form.intoDate;
                    this.submitting = true;
                    http.post('v1/worker/workerCheckin', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('提交成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleSelectPerson() {
            let params = {};
            params.subcontractorId = this.params.subcontractorId;
            params.disabledList = this.tableData;
            this.$emit('select-person', params);
        },
        updatePerson(data) {
            let map = {};
            this.tableData.forEach(item => {
                map[item.id] = true;
            });

            data.forEach(item => {
                if (!map[item.id]) {
                    item.isNew = true;
                    item.intoDate = '-';
                    this.tableData.push(item);
                }
            });
        },

        handleDelete(index, row) {
            this.tableData = this.tableData.filter(x => x.id != row.id);
        },
        checkParsonList(rule, value, callback) {
            if (!('checkinNum' in this.room)) {
                callback();
                return;
            }
            if (this.tableData.length > this.room.checkinNum) {
                callback(new Error('入住人数超过房间最大容纳人数'));
            } else {
                callback();
            }
        },
    }
};
</script>

<style scoped>
</style>
