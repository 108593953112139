<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="100px" :show-message="true" class="form-col2 form-cell">
            <div class="form-group-title"><h2>宿舍信息</h2></div>
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="data-item su-box clearfix">
                        <div class="st">{{ room.supplierName }}</div>
                        <div class="su">{{ room.buildName }}{{ room.floorName }}{{ room.roomName }}室</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">负责人：</div>
                        <div class="text">{{ room.supplierFuzeren }}</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">联系方式：</div>
                        <div class="text">{{ room.tel }}</div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">房型：</div>
                        <div class="text">{{ room.checkinNum }}人间</div>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="data-item">
                        <div class="label">住宿类型：</div>
                        <div class="text">{{ roomTypeText }}</div>
                    </div>
                </el-col>
                <!-- <el-col :span="8">
                    <div class="data-item">
                        <div class="label">住宿配置：</div>
                        <div class="text">空调、架子床、柜子</div>
                    </div>
                </el-col> -->
            </el-row>
            <div class="form-group-title">
                <h2>人员信息</h2>
            </div>
            <div class="datatable-wrap-1">
                <el-form-item label-width="0">
                    <div class="datatable">
                        <el-table
                            :data="tableData"
                            v-loading="tableLoading"
                            element-loading-background="rgba(255, 255, 255, 0.3)"
                            border
                            style="width: 100%">
                            <el-table-column
                                label="序号"
                                align="center"
                                width="80">
                                <template slot-scope="scope">
                                    <span>{{ scope.$index + 1 }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                label="人员姓名">
                            </el-table-column>
                            <el-table-column
                                prop="numId"
                                label="身份证号">
                            </el-table-column>
                            <el-table-column
                                prop="sex"
                                label="性别">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.sex == 0">男</span>
                                    <span v-if="scope.row.sex == 1">女</span>
                                    <span v-if="scope.row.sex != 0 && scope.row.sex != 1">-</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="intoDate"
                                label="入住时间">
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form-item>
            </div>
            <div class="form-group-title">
                <h2>选择房间</h2>
                <div class="info-box">
                    <span class="sc">已选择宿舍：{{ roomCount }}间</span>
                    <span class="hc">可入住：{{ holdPersonCount }}人</span>
                </div>
                <div class="btn-box">
                    <el-button type="primary" size="mini" @click="handleSelectRoom">选择房间</el-button>
                </div>
            </div>
            <div class="select-room-box">
                <el-form-item label-width="0" prop="changeRoomId" style="margin-bottom: 0;">
                    <template v-if="storeyList.length > 0">
                        <div
                            v-for="storey in storeyList"
                            :key="storey.storey.id"
                            class="item clearfix">
                            <div class="st"
                        >{{ storey.building.name }}{{ storey.storey.name }}</div>
                            <ul>
                                <li
                                    v-for="room in storey.roomList"
                                    :key="room.id"
                                    :class="{ 'close': !roomMap[room.id] }"
                                    @click="handleRoomClick(room)"
                                >{{ room.name }}<i :class="{ 'el-icon-check': roomMap[room.id], 'el-icon-close': !roomMap[room.id] }"></i></li>
                            </ul>
                        </div>
                    </template>
                    <div v-else class="empty">
                        <span @click="handleSelectRoom">选择房间</span>
                    </div>
                </el-form-item>
            </div>
            <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                    <el-form-item label="更换日期" prop="changeDate">
                        <el-date-picker
                            v-model="form.changeDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="resetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"

export default {
    name: 'Change',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            pickerOptions: {},
            storeyList: [],
            room: {},
            tableData: [],
            tableLoading: false,
            form: {
                roomId: this.params.id,
                changeRoomId: '',
                changeDate: '',
            },
            // personIdList: [],
            roomMap: {},
            rules: {
                changeRoomId: [
                    { required: true, message: '请选房间', trigger: 'change' },
                    { validator: this.checkParsonList, trigger: 'change'},
                ],
                changeDate: [{required: true, message: '请选择换房日期', trigger: 'change'}],
            },
            submitting: false
        }
    },
    computed: {
        roomTypeText() {
            switch(String(this.room.roomType)) {
                case '1': return "男宿";
                case '2': return "女宿";
                case '3': return "夫妻房";
                case '4': return "仓库";
                case '5': return "办公室";
                case '6': return "隔离室";
                case '7': return "沃舟自用房";
                case '8': return "其他";
                default: return "-";
            }
        },
        roomCount() {
            let count = 0;
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    storey.roomList.forEach(room => {
                        if (this.roomMap[room.id]) {
                            count++;
                        }
                    });
                }
            });
            return count;
        },
        holdPersonCount() {
            let personCount = 0;
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    storey.roomList.forEach(room => {
                        if (this.roomMap[room.id]) {
                            personCount += room.checkinNum;
                        }
                    });
                }
            });
            return personCount;
        },
    },
    created() {
        this.loadData();
    },
    watch: {
        // ['tableData']: {
        //     handler(value) {
        //         this.personIdList = value.filter(x=>x.isNew).map(x=>x.id);
        //     },
        //     immediate: true,
        // },
        roomMap: {
            immediate: true,
            deep: true,
            handler: function() {
                let list = [];
                this.storeyList.forEach(storey => {
                    if (storey.roomList) {
                        storey.roomList.forEach(room => {
                            if (this.roomMap[room.id]) {
                                list.push(room.id);
                            }
                        });
                    }
                });

                if (list.length == 0) {
                    this.form.changeRoomId = "";
                } else {
                    this.form.changeRoomId = list[0];
                }

                if (this.$refs.form) {
                    this.$refs.form.validateField("changeRoomId");
                }
            }
        },
    },
    methods: {
        ...common,
        ...verify,
        loadData() {
            let loading = this.load();
            let params = {};
            params.id = this.params.id;
            http.get('v1/build/roomDetail', {
                params: params
            }).then(req => {
                loading.close();
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;
                    this.room = data;
                    this.tableData = !data.workerList ? [] : data.workerList;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                loading.close();
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let data = {};

                    data.checkoutRoomId = this.form.roomId;
                    data.checkinRoomId = this.form.changeRoomId;
                    data.supplierId = this.params.subcontractorId;
                    data.day = this.form.changeDate;

                    this.submitting = true;
                    http.post('v1/build/exchangeRoom', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('提交成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleSelectPerson() {
            let params = {};
            params.subcontractorId = this.params.subcontractorId;
            params.disabledList = this.tableData;
            this.$emit('select-person', params);
        },
        updatePerson(data) {
            let map = {};
            this.tableData.forEach(item => {
                map[item.id] = true;
            });

            data.forEach(item => {
                if (!map[item.id]) {
                    item.isNew = true;
                    item.intoDate = '-';
                    this.tableData.push(item);
                }
            });
        },

        handleDelete(index, row) {
            this.tableData = this.tableData.filter(x => x.id != row.id);
        },
        checkParsonList(rule, value, callback) {
            if (!('checkinNum' in this.room)) {
                callback();
                return;
            }
            if (this.tableData.length > this.room.checkinNum) {
                callback(new Error('入住人数超过房间最大容纳人数'));
            } else {
                callback();
            }
        },
        handleSelectRoom() {
            this.$emit('select-room', this.storeyList, false);
        },
        updateStoreyGroup(data) {
            this.storeyList = data;
            let map = {};
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    storey.roomList.forEach(room => {
                        map[room.id] = true;
                        if (!(room.id in this.roomMap)) {
                            this.$set(this.roomMap, room.id, true);
                        }
                    });
                }
            });
            for (let id in this.roomMap) {
                if (!(id in map)) {
                    delete this.roomMap[id];
                }
            }
            this.roomMap = { ...this.roomMap };
        },

        handleRoomClick(room) {
            this.roomMap[room.id] = !this.roomMap[room.id];
        },
        handlePropertyAllChange(value) {
            if (value) {
                this.form.property = this.propertyList.map(x => x.id);
            } else {
                this.form.property = [];
            }
            this.property.isIndeterminate = false;
        },
        handlePropertyChange() {
            if (this.form.property.length == this.propertyList.length) {
                this.property.all = true;
                this.property.isIndeterminate = false;
            } else {
                if (this.form.property.length == 0) {
                    this.property.all = false;
                    this.property.isIndeterminate = false;
                } else {
                    this.property.isIndeterminate = true;
                }
            }
        },
    }
};
</script>

<style scoped>
</style>
