<template>
    <div ref="dialogInnerFull" class="dialog-inner-full" style="padding-top:0;">
        <div class="layout-left layout-left-1" style="width:260px;">
            <div class="filter-tree">
                <el-input
                    placeholder="搜索"
                    v-model="filterTreeText"
                    size="small">
                </el-input>
            </div>
            <div class="left-box-wrap" v-loading="treeLoading">
                <Scrollbar style="height:100%;">
                    <div class="left-box left-box2">
                        <el-tree
                            ref="leftTree"
                            :show-checkbox="false"
                            check-strictly
                            :expand-on-click-node="false"
                            :data="treeData"
                            :filter-node-method="filterNode"
                            default-expand-all
                            node-key="id"
                            :props="{ children: 'children', label: 'name' }"
                            @node-click="handleNodeClick">
                            <div slot-scope="{ node, data }">
                                <div class="tree-name">{{ data.name }}</div>
                            </div>
                        </el-tree>
                    </div>
                </Scrollbar>
            </div>
        </div>
        <div class="frame-content-wrap layout-right" style="margin-left:260px;">
            <div class="filter">
                <div class="inline">
                    <el-input v-model="filters.name" placeholder="宿舍编号" size="small"></el-input>
                </div>
                <!-- <div class="inline ml-8">
                    <el-select v-model="filters.status" clearable placeholder="宿舍状态" size="small">
                        <el-option label="待分配" value="1"></el-option>
                        <el-option label="使用中" value="2"></el-option>
                        <el-option label="停用" value="3"></el-option>
                    </el-select>
                </div>
                <div class="inline ml-8">
                    <el-select v-model="filters.useStatus" clearable placeholder="入住状态" size="small">
                        <el-option label="空置" value="1"></el-option>
                        <el-option label="未住满" value="2"></el-option>
                        <el-option label="满额入住" value="3"></el-option>
                    </el-select>
                </div> -->
                <div class="inline ml-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        :height="dataTableHeight"
                        @select="handleSelect"
                        @select-all="handleSelect"
                        style="width: 100%"
                        :header-row-class-name="hideSelectAll">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="60">
                            <template slot-scope="scope">
                                <span>{{ (curPageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="roomName"
                            label="宿舍编号"
                            align="left">
                        </el-table-column>
                        <el-table-column
                            prop="roomType"
                            label="住宿类型"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <template v-if="scope.row.roomType == 1">男宿</template>
                                <template v-if="scope.row.roomType == 2">女宿</template>
                                <template v-if="scope.row.roomType == 3">夫妻房</template>
                                <template v-if="scope.row.roomType == 4">仓库</template>
                                <template v-if="scope.row.roomType == 5">办公室</template>
                                <template v-if="scope.row.roomType == 6">隔离室</template>
                                <template v-if="scope.row.roomType == 7">沃舟自用房</template>
                                <template v-if="scope.row.roomType == 8">其他</template>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column
                            prop="property"
                            label="宿舍资产">
                        </el-table-column> -->
                        <el-table-column
                            prop="status"
                            label="宿舍状态"
                            width="100">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status == 1 && scope.row.supplierId == 0" type="primary" disable-transitions>待分配</el-tag>
                                <el-tag v-if="scope.row.status == 1 && scope.row.supplierId != 0" type="info" disable-transitions>使用中</el-tag>
                                <el-tag v-if="scope.row.status == 0" type="danger" disable-transitions>停用</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="checkinNum"
                            label="可入住人数"
                            width="100">
                        </el-table-column>
                        <!-- <el-table-column
                            prop="createTime"
                            label="创建时间"
                            align="center"
                            width="150">
                        </el-table-column> -->
                        <el-table-column
                            prop="remark"
                            align="left"
                            label="备注">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import Scrollbar from '@/components/Scrollbar'

export default {
    name: 'SelectRoom',
    props: {
        params: {
            type: Array,
            required: true
        },
        multiple: {
            type: Boolean,
            default() {
                return true;
            },
        }
    },
    components: {
        Scrollbar,
    },
    data() {
        return {
            dataTableHeight: 'auto',
            treeData: [],
            treeLoading: false,

            nodeId: '',
            nodeData: {},
            filterTreeText: '',
            storeyId: '',

            filters: {
                name: '',
                status: '',
                useStatus: ''
            },

            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            curPageNum: 1,

            tableLoading: false,
            selectionMap: {},
            parentMap: {},
        };
    },
    computed: {
        hideSelectAll() {
            if (this.multiple) {
                return "";
            }
            return "hide-select-all";
        },
    },
    created () {
        this.loadTree();
        this.init();
    },
    mounted() {
        this.$nextTick(this.updateTableHeight);
        window.addEventListener('resize', this.updateTableHeight, false);
    },
    watch: {
        filterTreeText(val) {
            this.$refs.leftTree.filter(val);
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateTableHeight, false);
    },
    methods: {
        ...common,
        init() {
            this.params.forEach(x => {
                let parents = [
                    x.building,
                    x.storey,
                ];

                if (x.roomList) {
                    x.roomList.forEach(i => {
                        this.selectionMap[i.id] = i,
                        this.parentMap[i.id] = parents;
                    });
                }
            });
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        updateTableHeight() {
            this.dataTableHeight = this.$refs.dialogInnerFull.clientHeight - 147;
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },
        loadTree() {
            this.treeLoading = true;
            http.get('v1/build/tree').then(req => {
                this.treeLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    this.treeData = [{
                        id: '0',
                        name: '全部',
                        children: req.data.data,
                    }];
                    this.$nextTick(() => {
                        if (this.treeData && this.treeData.length > 0 && this.$refs.leftTree) {
                            if (this.nodeId != '') {
                                let node = this.$refs.leftTree.getNode(this.nodeId);
                                if (!node) {
                                    this.nodeId = this.treeData[0].id;
                                    this.nodeData = this.treeData[0];
                                } else {
                                    if (node.level > 1) {
                                        this.storeyId = this.nodeId;
                                    }
                                }
                            } else {
                                this.nodeId = this.treeData[0].id;
                                this.nodeData = this.treeData[0];
                            }

                            this.$refs.leftTree.setCurrentKey(this.nodeId);

                            this.loadTable();
                        }
                    });
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.treeLoading = false;
                this.httpCatch(err);
            });
        },
        loadTable() {
            let {
                name,
                status,
                useStatus
            } = this.filters;

            let params = {};

            params.pid = this.nodeId;
            params.status = 1;
            params.supplierId = 0;

            this.tableLoading = true;
            http.get('v1/build/roomList', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;

                    data = data.filter((item) => {
                        if (name != '' && item.roomName.indexOf(name) == -1) {
                            return false;
                        }

                        if (status != '') {
                            if (status == 1) {
                                if (!(item.status == 1 && item.supplierId == 0)) {
                                    return false;
                                }
                            } else if (status == 2) {
                                if (!(item.status == 1 && item.supplierId != 0)) {
                                    return false;
                                }
                            } else if (status == 3) {
                                if (item.status != 0) {
                                    return false;
                                }
                            }
                        }

                        if (useStatus != '') {
                            if (useStatus == 1) {
                                if (item.checkin != 0) {
                                    return false;
                                }
                            } else if (useStatus == 2) {
                                if (item.checkin >= item.checkinNum) {
                                    return false;
                                }
                            } else if (useStatus == 3) {
                                if (item.checkin < item.checkinNum) {
                                    return false;
                                }
                            }
                        }

                        return true;
                    });

                    this.totalNum = data.length;

                    let startIndex = this.pageSize*(this.pageNum - 1);
                    let endIndex = startIndex + this.pageSize;
                    data = data.filter((_, index) => {
                        return index >= startIndex && index < endIndex;
                    });

                    data.forEach(item => {
                        item.id = item.roomId;
                        item.name = item.roomName;
                        item.pid = item.floorId;
                        // item.createTime = item.createTime.substr(0, 16);
                    });

                    this.curPageNum = this.pageNum;

                    this.tableData = data;

                    this.$nextTick(() => {
                        this.selectItem();
                    });
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        handleNodeClick(data) {
            this.nodeId = data.id;
            this.nodeData = data;

            let node = this.$refs.leftTree.getNode(this.nodeId);
            if (node && node.level > 1) {
                this.storeyId = this.nodeId;
            } else {
                this.storeyId = '';
            }

            this.loadTable();
        },
        selectItem() {
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.selectionMap[this.tableData[i].id]) {
                    this.$refs.currentTable.toggleRowSelection(this.tableData[i], true);
                } else {
                    this.$refs.currentTable.toggleRowSelection(this.tableData[i], false);
                }
            }
        },
        handleSelect(selections, row) {
            if (this.multiple) {
                for (let i = 0; i < this.tableData.length; i++) {
                    delete this.selectionMap[this.tableData[i].id];
                    delete this.parentMap[this.tableData[i].id];
                }
                for (let i = 0; i < selections.length; i++) {
                    this.selectionMap[selections[i].id] = selections[i];
                    this.parentMap[selections[i].id] = this.getParents(selections[i].pid);
                }
            } else {
                let flag = true;
                if (this.selectionMap[row.id]) {
                    flag = false;
                }

                this.selectionMap = {};
                this.parentMap = {};

                if (flag) {
                    this.selectionMap[row.id] = row;
                    this.parentMap[row.id] = this.getParents(row.pid);
                }

                this.$nextTick(() => {
                    this.selectItem();
                });
            }
        },
        getParents(id){
            let nodeData;
            if (this.nodeData.type == 0) {
                nodeData = this.nodeData.children.find(x => x.id == id);
            } else if (this.nodeData.id == '0') {
                out:
                for (let i = 0; i < this.nodeData.children.length; i++) {
                    let sub1 = this.nodeData.children[i];
                    if (!sub1.children) {
                        continue;
                    }
                    for (let j = 0; j < sub1.children.length; j++) {
                        if (sub1.children[j].id == id) {
                            nodeData = sub1.children[j];
                            break out;
                        }
                    }
                }
            } else {
                if (this.nodeData.id == id) {
                    nodeData = this.nodeData;
                } else {
                    return [];
                }
            }

            let node = this.$refs.leftTree.getNode(nodeData.id);
            let arr = [];
            let p = node;
            while(p && p.parent) {
                arr.push(p);
                p = p.parent;
            }
            let v = [];
            for (let i = arr.length - 2; i >= 0; i--) {
                v.push(arr[i].data);
            }
            return v;
        },
        getStoreyGroup(){
            let map = {};
            let arr = [];
            for(let id in this.selectionMap) {
                let room = this.selectionMap[id];
                let storey = this.parentMap[id];

                if (storey.length == 2) {
                    let item = map[storey[1].id];
                    if (!item) {
                        item = {
                            building: {
                                id: storey[0].id,
                                name: storey[0].name,
                            },
                            storey: {
                                id: storey[1].id,
                                name: storey[1].name,
                            },
                            roomList: [],
                        };
                        arr.push(item);
                        map[storey[1].id] = item;
                    }
                    item.roomList.push(room);
                }
            }
            return arr;
        },
    }
}
</script>

<style scoped>
</style>
