<template>
    <div ref="dialogInnerFull" class="dialog-inner-full" style="padding-top:0;">
        <div class="frame-content-wrap">
            <div class="filter">
                <div class="inline mr-8">
                    <el-input v-model="filters.name" placeholder="姓名" size="small"></el-input>
                </div>
                <div class="inline mr-8">
                    <el-input v-model="filters.numId" placeholder="身份证" size="small"></el-input>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.sex" clearable placeholder="性别" size="small">
                        <el-option label="男" :value="0"></el-option>
                        <el-option label="女" :value="1"></el-option>
                    </el-select>
                </div>
                <div class="inline">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div class="datatable">
                    <el-table
                        ref="currentTable"
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        :height="dataTableHeight"
                        @select="handleSelect"
                        @select-all="handleSelect"
                        style="width: 100%">
                        <el-table-column
                            type="selection"
                            width="55"
                            align="center"
                            :selectable="handleSelectable">
                        </el-table-column>
                        <el-table-column
                            label="序号"
                            align="center"
                            width="60">
                            <template slot-scope="scope">
                                <span>{{ (curPageNum - 1) * pageSize + scope.$index + 1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="姓名"
                            align="left">
                        </el-table-column>
                        <el-table-column
                            prop="numId"
                            label="身份证号"
                            align="left">
                        </el-table-column>
                        <el-table-column
                            prop="sex"
                            label="性别"
                            align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.sex == 0">男</span>
                                <span v-if="scope.row.sex == 1">女</span>
                                <span v-if="scope.row.sex != 0 && scope.row.sex != 1">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            label="入住状态">
                            <template slot-scope="scope">
                                <el-tag v-if="scope.row.status == 0" type="success" disable-transitions>未入住</el-tag>
                                <el-tag v-if="scope.row.status == 2" type="info" disable-transitions>已退宿</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :background="true"
                        :current-page="pageNum"
                        :page-sizes="[50, 100, 200, 300, 400, 500]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalNum">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'

export default {
    name: 'SelectPerson',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dataTableHeight: 'auto',

            filters: {
                name: '',
                numId: '',
                sex: ''
            },
            
            tableData: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            curPageNum: 1,

            tableLoading: false,
            selectionMap: {},
            disabledMap: {},
        };
    },
    created () {
        this.init();
    },
    mounted() {
        this.$nextTick(this.updateTableHeight);
        window.addEventListener('resize', this.updateTableHeight, false);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateTableHeight, false);
    },
    methods: {
        ...common,
        init() {
            if (this.params.disabledList) {
                this.params.disabledList.forEach(x => {
                    this.disabledMap[x.id] = x;
                });
            }
            if (this.params.selectionList) {
                this.params.selectionList.forEach(x => {
                    this.selectionMap[x.id] = x;
                });
            }
            console.log(this.disabledMap);
            this.loadTable();
        },
        updateTableHeight() {
            this.dataTableHeight = this.$refs.dialogInnerFull.clientHeight - 147;
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },
        handleSelectable(row) {
            if (this.disabledMap[row.id]) {
                return false;
            }
            return true;
        },
        loadTable() {
            let {
                name,
                numId,
                sex,
            } = this.filters;

            let params = {};
            // params.name = name;
            // params.numId = numId;
            // params.sex = sex;
            params.supplierId = this.params.subcontractorId;
            
            this.tableLoading = true;
            http.get('v1/worker/workerUncheckInlist', {
                params: params
            }).then(req => {
                this.tableLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;

                    data = data.filter((item) => {
                        if (name != '' && item.name.indexOf(name) == -1) {
                            return false;
                        }

                        if (numId != '' && item.numId != numId) {
                            return false;
                        }

                        if (sex != '' && item.sex != sex) {
                            return false
                        }

                        return true;
                    });

                    this.totalNum = data.length;

                    let startIndex = this.pageSize*(this.pageNum - 1);
                    let endIndex = startIndex + this.pageSize;
                    data = data.filter((_, index) => {
                        return index >= startIndex && index < endIndex;
                    });

                    data.forEach(item => {
                        item.createTime = item.createTime.substr(0, 16);
                    });

                    this.curPageNum = this.pageNum;

                    this.tableData = data;

                    this.$nextTick(() => {
                        this.selectItem();
                    });
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.tableLoading = false;
                this.httpCatch(err);
            });
        },
        selectItem() {
            for (let i = 0; i < this.tableData.length; i++) {
                if (this.selectionMap[this.tableData[i].id]) {
                    this.$refs.currentTable.toggleRowSelection(this.tableData[i], true);
                }
            }
        },
        handleSelect(selections) {
            for (let i = 0; i < this.tableData.length; i++) {
                delete this.selectionMap[this.tableData[i].id];
            }

            for (let i = 0; i < selections.length; i++) {
                this.selectionMap[selections[i].id] = selections[i];
            }
        },
        getData(){
            let arr = [];
            for(let id in this.selectionMap) {
                let person = this.selectionMap[id];
                arr.push(person);
            }
            return arr;
        },
    }
}
</script>

<style scoped>
</style>