<template>
    <div class="frame-content">
        <div class="layout-left" style="width:260px;">
            <div class="filter-tree">
                <el-input
                    placeholder="搜索"
                    v-model="filterTreeText"
                    size="small">
                </el-input>
            </div>
            <div class="left-box-wrap" v-loading="treeLoading">
                <Scrollbar style="height:100%;">
                    <div class="left-box">
                        <el-tree
                            ref="leftTree"
                            :expand-on-click-node="false"
                            :data="treeData"
                            :filter-node-method="filterNode"
                            default-expand-all
                            node-key="id"
                            :props="{ children: 'children', label: 'name' }"
                            @node-click="handleNodeClick">
                        </el-tree>
                    </div>
                </Scrollbar>
            </div>
        </div>
        <div class="frame-content-wrap layout-right" style="margin-left:260px;">
            <div class="filter">
                <div class="inline mr-8">
                    <el-input v-model="filters.identifier" placeholder="宿舍编号" size="small"></el-input>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.checkinNum" clearable placeholder="房型" size="small">
                        <el-option label="4人间" value="4"></el-option>
                        <el-option label="6人间" value="6"></el-option>
                        <el-option label="8人间" value="8"></el-option>
                        <el-option label="10人间" value="10"></el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.roomType" clearable placeholder="住宿类型" size="small">
                        <el-option label="男宿" value="1"></el-option>
                        <el-option label="女宿" value="2"></el-option>
                        <el-option label="夫妻房" value="3"></el-option>
                        <el-option label="仓库" value="4"></el-option>
                        <el-option label="办公室" value="5"></el-option>
                        <el-option label="隔离室" value="6"></el-option>
                        <el-option label="沃舟自用房" value="7"></el-option>
                        <el-option label="其他" value="8"></el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-select v-model="filters.supplierId" filterable clearable placeholder="分包单位" size="small" style="width: 300px;">
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="inline mr-8">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="loadTable">查询</el-button>
                </div>
                <div class="inline">
                    <el-button type="warning" size="small" @click="handleApplyRoom()" :disabled="$store.state.org.type != 2">分包单位入住</el-button>
                </div>
            </div>
            <div class="datatable-wrap mt-10">
                <div v-if="dataList.length == 0" v-loading="dataLoading" class="data-loading">暂无数据</div>
                <el-collapse v-if="dataList.length > 0" :accordion="false" v-loading="dataLoading" v-model="storeyUnfold" @change="handleCollapseChange">
                    <el-collapse-item v-for="(item, index) in dataList" :key="item.id" :name="item.id" class="storey" :class="{'mt-10': index > 0 }">
                        <template slot="title">
                            <div class="info">
                                <h3>{{ item.storey }}</h3>
                                <ul>
                                    <li class="total">总房间数: {{ item.roomCount }}</li>
                                    <li class="room">未满房: {{ item.notFullCount }}</li>
                                    <li class="room-1">满房: {{ item.fullCount }}</li>
                                    <li class="room-2">空房: {{ item.notUseRoomCount }}</li>
                                    <li class="bed">已住人数: {{ item.useBedCount }}</li>
                                    <li class="bed-1">空床位: {{ item.notUseBedCount }}</li>
                                </ul>
                                <span v-if="!dataStateList[item.id].fold" class="btn" @click="handleFlod(index, item)">收起</span>
                                <span v-if="dataStateList[item.id].fold" class="btn" @click="handleUnflod(index, item)">展开</span>
                            </div>
                        </template>
                        <div class="room-list">
                            <div v-for="room in item.roomList" :key="room.id" class="box-card-wrap">
                                <el-card class="box-card">
                                    <div slot="header" class="clearfix">
                                        <span class="t">{{ room.identifier }}室({{ room.type }})</span>
                                        <el-tag v-if="room.status == 1 && room.subcontractorId == 0" type="primary" style="float:right;" disable-transitions>待分配</el-tag>
                                        <el-tag v-if="room.status == 1 && room.subcontractorId != 0" type="info" style="float:right;" disable-transitions>使用中</el-tag>
                                        <el-tag v-if="room.status == 0" type="danger" style="float:right;" disable-transitions>停用</el-tag>
                                    </div>
                                    <div class="room-type">房型：{{ room.roomType }}</div>
                                    <div class="bed-count">床位数：{{ room.useBedCount }}/{{ room.bedCount }}</div>
                                    <div class="out-date">退房日期：{{ room.outDate }}</div>
                                    <!-- <div class="pro">{{ room.property }}</div> -->
                                    <div class="sub">{{ room.subcontractor }}</div>
                                    <div class="btn"><el-button
                                        :disabled="room.subcontractorId == null || room.subcontractorId == '' || room.subcontractorId == 0"
                                        type="primary"
                                        size="mini"
                                        @click="handleUnderDevelopment(index, room)">安排入住</el-button>
                                        <el-button
                                            :disabled="room.subcontractorId == null || room.subcontractorId == '' || room.subcontractorId == 0"
                                            type="warning"
                                            size="mini"
                                            @click="handleChange(index, room)">更换房间</el-button></div>
                                </el-card>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>

        <el-dialog
            title="安排入住"
            v-if="arrangeDialog.create"
            :visible.sync="arrangeDialog.visible"
            :close-on-click-modal="false"
            @closed="handleArrangeClosed"
            class="custom-dialog big-dialog">
            <Arrange
                ref="arrange"
                :params="arrangeDialog.params"
                @select-person="handleArrangeSelectPerson"
                @done="handleArrangeDone"
            ></Arrange>
        </el-dialog>

        <el-dialog
            title="更换房间"
            v-if="changeDialog.create"
            :visible.sync="changeDialog.visible"
            :close-on-click-modal="false"
            @closed="handleChangeClosed"
            class="custom-dialog big-dialog">
            <Change
                ref="change"
                :params="changeDialog.params"
                @select-room="handleSelectRoom"
                @done="handleChangeDone"
            ></Change>
        </el-dialog>

        <el-dialog
            title="选择人员"
            v-if="selectPersonDialog.create"
            :visible.sync="selectPersonDialog.visible"
            :close-on-click-modal="false"
            @closed="handleSelectPersonClosed"
            class="custom-dialog big-dialog">
            <SelectPerson
                ref="selectPerson"
                :params="selectPersonDialog.params"
                @done="handleSelectPersonDone"
            ></SelectPerson>
            <div slot="footer" class="custom-dialog-footer">
                <span class="footer-inner">
                    <el-button @click="selectPersonDialog = { create: true, visible: false, params: {} }" size="small">取 消</el-button>
                    <el-button type="primary" @click="handleSelectPersonComfirm" size="small">确 定</el-button>
                </span>
            </div>
        </el-dialog>

        <el-dialog
            title="选择房间"
            v-if="selectRoomDialog.create"
            :visible.sync="selectRoomDialog.visible"
            :close-on-click-modal="false"
            @closed="handleSelectRoomClosed"
            class="custom-dialog big-dialog">
            <SelectRoom
                ref="selectRoom"
                :multiple="selectRoomMultiple"
                :params="selectRoomDialog.params"
                @done="handleSelectRoomDone"
            ></SelectRoom>
            <div slot="footer" class="custom-dialog-footer">
                <span class="footer-inner">
                    <el-button @click="selectRoomDialog = { create: true, visible: false, params: {} }" size="small">取 消</el-button>
                    <el-button type="primary" @click="handleSelectRoomComfirm" size="small">确 定</el-button>
                </span>
            </div>
        </el-dialog>

        <el-dialog
            title="分包入住"
            v-if="applyRoomDialog.create"
            :visible.sync="applyRoomDialog.visible"
            :close-on-click-modal="false"
            @closed="handleApplyRoomClosed"
            class="custom-dialog big-dialog">
            <ApplyRoom
                ref="applyRoom"
                :params="applyRoomDialog.params"
                @select-room="handleApplyRoomSelectRoom"
                @done="handleApplyRoomDone"
            ></ApplyRoom>
        </el-dialog>
    </div>
</template>

<script>
import common from '@/utils/common'
import http from '@/utils/http'
import Scrollbar from '@/components/Scrollbar'
import Arrange from './Arrange'
import Change from './Change'
import SelectPerson from './SelectPerson'
import SelectRoom from '../household/subcontractor/SelectRoom'
import ApplyRoom from '../household/subcontractor/ApplyRoom'

export default {
    name: 'Manage',
    components: {
        Scrollbar,
        Arrange,
        Change,
        SelectPerson,
        SelectRoom,
        ApplyRoom,
    },
    data() {
        return {
            supplierList: [],
            treeData: [],
            treeLoading: false,

            nodeId: '',
            nodeData: {},
            filterTreeText: '',
            storeyId: '',

            filters: {
                identifier: '',
                status: '',
                useStatus: '',
                checkinNum: '',
                roomType: '',
                supplierId: '',
            },

            dateRange: [],

            dataList: [],
            dataStateList: {},
            storeyUnfold: [],

            totalNum: 0,
            pageNum: 1,
            pageSize: 50,

            dataLoading: false,
            firstLoading: true,

            arrangeDialog: {
                create: false,
                visible: false,
                params: {},
            },

            changeDialog: {
                create: false,
                visible: false,
                params: {},
            },

            selectPersonDialog: {
                create: false,
                visible: false,
                params: {},
            },

            selectRoomDialog: {
                create: false,
                visible: false,
                params: {},
            },

            applyRoomDialog: {
                create: false,
                visible: false,
                params: {},
            },

            selectRoomMultiple: false,
        };
    },
    created () {
        this.loadTree();
        this.loadSupperList();
    },
    watch: {
        filterTreeText(val) {
            this.$refs.leftTree.filter(val);
        },
    },
    methods: {
        ...common,
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadTree() {
            this.treeLoading = true;
            http.get('v1/build/tree').then(req => {
                this.treeLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    this.treeData = [{
                        id: '0',
                        name: '全部',
                        children: req.data.data,
                    }];
                    this.$nextTick(() => {
                        if (this.treeData && this.treeData.length > 0 && this.$refs.leftTree) {
                            if (this.nodeId != '') {
                                let node = this.$refs.leftTree.getNode(this.nodeId);
                                if (!node) {
                                    this.nodeId = this.treeData[0].id;
                                    this.nodeData = this.treeData[0];
                                } else {
                                    if (node.level > 1) {
                                        this.storeyId = this.nodeId;
                                    }
                                }
                            } else {
                                this.nodeId = this.treeData[0].id;
                                this.nodeData = this.treeData[0];
                            }

                            this.$refs.leftTree.setCurrentKey(this.nodeId);

                            this.loadTable();
                        }
                    });
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.treeLoading = false;
                this.httpCatch(err);
            });
        },
        loadTable() {
            let {
                identifier,
                // status,
                // useStatus,
                checkinNum,
                roomType,
                supplierId,
            } = this.filters;

            let params = {};
            params.pid = this.nodeId;

            if (identifier != '' && identifier != null) {
                params.name = identifier;
            }

            // if (status != '' && status != null) {
            //     params.status = status;
            // }

            // if (useStatus != '' && useStatus != null) {
            //     params.useStatus = useStatus;
            // }

            if (checkinNum != '' && checkinNum != null) {
                params.checkinNum = checkinNum;
            }

            if (roomType != '' && roomType != null) {
                params.roomType = roomType;
            }

            if (supplierId != '' && supplierId != null) {
                params.supplierId = supplierId;
            }

            params.pageNum = this.pageNum;
            params.pageSize = this.pageSize;

            this.dataLoading = true;
            http.get('v1/build/roomList', {
                params: params
            }).then(req => {
                this.firstLoading = false;
                this.dataLoading = false;
                if (this.httpThen(req)) {
                    return;
                }
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;

                    if (!data) {
                        data = [];
                    }

                    let map = {};
                    data.forEach(x => {
                        let item = map[x.floorId];
                        if (!item) {
                            item = {
                                id: x.floorId,
                                buildId: x.buildId,
                                storey: x.buildName+x.floorName,
                                roomCount: 0,
                                notFullCount: 0,
                                fullCount: 0,
                                notUseRoomCount: 0,
                                useBedCount: 0,
                                notUseBedCount: 0,
                                roomList: [],
                            };
                            map[x.floorId] = item;
                        }

                        let room = {
                            id: x.roomId,
                            buildName: x.buildName,
                            floorName: x.floorName,
                            identifier: x.roomName,
                            type: this.roomTypeText(x.roomType),
                            status: x.status,
                            roomType: x.checkinNum + '人间',
                            useBedCount: x.checkin == null ? 0 : x.checkin,
                            bedCount: x.checkinNum,
                            //property: '架子床 柜子 空调',
                            subcontractorId: x.supplierId,
                            subcontractor: x.supplierName,
                            outDate: ((!x.estimateCheckoutDay) ? '-' : x.estimateCheckoutDay),
                        };

                        item.roomCount++;
                        if (room.useBedCount < room.bedCount) {
                            item.notFullCount++;
                        } else {
                            item.fullCount++;
                        }

                        if (room.useBedCount == 0) {
                            item.notUseRoomCount++;
                        }

                        item.useBedCount += room.useBedCount;
                        item.notUseBedCount += room.bedCount - room.useBedCount;

                        item.roomList.push(room);
                    });

                    data = [];
                    this.dataStateList = {};
                    for (let key in map) {
                        let item = map[key];
                        data.push(item);
                        item.roomCount = item.roomList.length;
                        this.dataStateList[item.id] = { fold: true };
                    }

                    this.dataList = data;

                    if (data.length > 0) {
                        this.storeyUnfold = [data[0].id];
                        this.dataStateList[data[0].id] = { fold: false };
                    }
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.dataLoading = false;
                this.httpCatch(err);
            });
        },
        roomTypeText(v) {
            switch(String(v)) {
                case '1': return "男宿";
                case '2': return "女宿";
                case '3': return "夫妻房";
                case '4': return "仓库";
                case '5': return "办公室";
                case '6': return "隔离室";
                case '7': return "沃舟自用房";
                case '8': return "其他";
                default: return "-";
            }
        },
        // handleNodeClick(data, node, vuecomponent) {
        handleNodeClick(data) {
            console.log(data)
            this.nodeId = data.id;
            this.nodeData = data;

            let node = this.$refs.leftTree.getNode(this.nodeId);
            if (node && node.level > 1) {
                this.storeyId = this.nodeId;
            } else {
                this.storeyId = '';
            }

            this.loadTable();
        },
        handleSizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loadTable();
        },
        handleCurrentChange(pageNum) {
            this.pageNum = pageNum;
            this.loadTable();
        },

        handleUnderDevelopment() {
            this.showWarn("正在开发中，敬请期待!");
        },

        // 安排入住
        handleArrange(index, row) {
            this.arrangeDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.arrangeDialog = { create: true, visible: true, params: row };
            });
        },
        handleArrangeDone() {
            this.arrangeDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleArrangeClosed() {
            this.arrangeDialog = { create: false, visible: false, params: {} };
        },

        handleArrangeSelectPerson(data) {
            this.handleSelectPerson(data);
        },

        // 选择人员
        handleSelectPerson(data) {
            this.selectPersonDialog = { create: true, visible: false, params: data };
            this.$nextTick(() => {
                this.selectPersonDialog = { create: true, visible: true, params: data };
            });
        },
        handleSelectPersonDone() {
            this.selectPersonDialog = { create: true, visible: false, params: {} };
        },
        handleSelectPersonClosed() {
            this.selectPersonDialog = { create: false, visible: false, params: {} };
        },

        handleSelectPersonComfirm() {
            let data = this.$refs.selectPerson.getData();
            this.$refs.arrange.updatePerson(data);
            this.handleSelectPersonDone();
        },

        // 更换房间
        handleChange(index, row) {
            this.changeDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.changeDialog = { create: true, visible: true, params: row };
            });
        },
        handleChangeDone() {
            this.changeDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleChangeClosed() {
            this.changeDialog = { create: false, visible: false, params: {} };
        },

        // 选择房间
        handleSelectRoom(data, multiple) {
            this.selectRoomMultiple = multiple;
            console.log(multiple);
            this.selectRoomDialog = { create: true, visible: false, params: data };
            this.$nextTick(() => {
                this.selectRoomDialog = { create: true, visible: true, params: data };
            });
        },

        handleSelectRoomDone() {
            this.selectRoomDialog = { create: true, visible: false, params: [] };
        },
        handleSelectRoomClosed() {
            this.selectRoomDialog = { create: false, visible: false, params: [] };
        },
        handleSelectRoomComfirm() {
           let storeyGroup = this.$refs.selectRoom.getStoreyGroup();
           console.log(this.selectRoomMultiple);
           if (this.selectRoomMultiple) {
               this.$refs.applyRoom.updateStoreyGroup(storeyGroup);
           } else {
               this.$refs.change.updateStoreyGroup(storeyGroup);
           }
           this.handleSelectRoomDone();
        },

        handleCollapseChange(names) {
            this.dataList.forEach(item => {
                this.dataStateList[item.id] = { fold: true };
            });
            names.forEach(id => {
                this.dataStateList[id] = { fold: false };
            });
        },

        handleFlod(index, item) {
            this.dataStateList[item.id] = { fold: true };
        },
        handleUnflod(index, item) {
            this.dataStateList[item.id] = { fold: false };
        },

        // 申请房间
        handleApplyRoom() {
            let row = {};
            this.applyRoomDialog = { create: true, visible: false, params: row };
            this.$nextTick(() => {
                this.applyRoomDialog = { create: true, visible: true, params: row };
            });
        },

        handleApplyRoomDone() {
            this.applyRoomDialog = { create: true, visible: false, params: {} };
            this.loadTable();
        },
        handleApplyRoomClosed() {
            this.applyRoomDialog = { create: false, visible: false, params: {} };
        },
        handleApplyRoomSelectRoom(data) {
            this.handleSelectRoom(data, true);
        },

        handleImport() {},
        handleExportQr() {},
    }
}
</script>

<style scoped>
</style>
