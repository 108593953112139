<template>
    <div class="dialog-inner" style="padding-top:0;">
        <el-form ref="form" :model="form" :rules="rules" label-width="150px" :show-message="true" class="form-col2">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item label="分包单位" prop="supplierId">
                        <el-select v-model="form.supplierId" placeholder="请选择" size="small" filterable @change="handleSelectSupplier">
                            <el-option 
                                v-for="item in supplierList" 
                                :key="item.id" 
                                :label="item.name" 
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="费用类型" prop="costTypeId">
                        <el-select
                            v-model="form.costTypeId"
                            placeholder="请选择"
                            size="small"
                            clearable
                            filterable>
                            <el-option
                                v-for="item in costTypeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="form-group-title">
                <h2>选择房间</h2>
                <div class="info-box">
                    <span class="sc">已选择宿舍：{{ roomCount }}间</span>
                    <span class="hc">可入住：{{ holdPersonCount }}人</span>
                    <span class="sc1 sc0">押金余额：{{ deposit == '-' ? '-' : deposit + '元' }}</span>
                    <span class="sc1">已租用宿舍：{{ useRoomCount == '-' ? '-' : useRoomCount + '间' }}</span>
                    <!-- <span class="sc1">可选择宿舍：{{ (canRoomCount == '-' || canRoomCount === null) ? '-' : canRoomCount + '间' }}</span> -->
                </div>
                <div class="btn-box">
                    <el-button type="primary" size="mini" @click="handleSelectRoom" :disabled="deposit == '-'">选择房间</el-button>
                </div>
            </div>
            <div class="select-room-box">
                <el-form-item label-width="0" prop="roomIdList" style="margin-bottom: 0;">
                    <template v-if="storeyList.length > 0">
                        <div
                            v-for="storey in storeyList"
                            :key="storey.storey.id"
                            class="item clearfix">
                            <div class="st"
                        >{{ storey.building.name }}{{ storey.storey.name }}</div>
                            <ul>
                                <li
                                    v-for="room in storey.roomList"
                                    :key="room.id"
                                    :class="{ 'close': !roomMap[room.id] }"
                                    @click="handleRoomClick(room)"
                                >{{ room.name }}<i :class="{ 'el-icon-check': roomMap[room.id], 'el-icon-close': !roomMap[room.id] }"></i></li>
                            </ul>
                        </div>
                    </template>
                    <div v-else class="empty">
                        <!-- <span @click="handleSelectRoom">选择房间</span> -->
                        <span>请选择房间</span>
                    </div>
                </el-form-item>
            </div>
            <el-row :gutter="20" class="mt-5">
                <el-col :span="8">
                    <el-form-item label="入住日期" prop="intoDate">
                        <el-date-picker
                            v-model="form.intoDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="预计退房日期" prop="outDate">
                        <el-date-picker
                            v-model="form.outDate"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8" class="mix-cell">
                    <el-form-item label="计费开始时间：" prop="chargingDay">
                        <el-date-picker
                            v-model="form.chargingDay"
                            type="date"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            size="small"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <!-- <div class="form-group-title"><h2>资产配置</h2></div>
            <div class="form-check-box">
                <el-form-item label-width="0" prop="property">
                    <el-checkbox
                        :indeterminate="property.isIndeterminate"
                        v-model="property.all"
                        @change="handlePropertyAllChange"
                        style="float:left;margin-right:30px;"
                    >全选</el-checkbox>
                    <el-checkbox-group
                        v-model="form.property"
                        @change="handlePropertyChange">
                        <el-checkbox
                            v-for="item in propertyList"
                            :key="item.id"
                            :label="item.id"
                            name="property"
                        >{{ item.name }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </div> -->
            <!-- <div class="form-group-title">
                <h2>费用合计</h2>
                <div class="info-box">
                    <span class="sc">押金总计：{{ depositTotal }}元</span>
                    <span class="hc">租金总计：{{ rentTotal }}元/月</span>
                </div>
            </div>
            <div class="datatable-wrap-1">
                <div class="datatable">
                    <el-table
                        :data="tableData"
                        v-loading="tableLoading"
                        element-loading-background="rgba(255, 255, 255, 0.3)"
                        border
                        style="width: 100%">
                        <el-table-column
                            label="序号"
                            align="center"
                            width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.$index + 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="资产名称"
                            width="200">
                        </el-table-column>
                        <el-table-column
                            prop="deposit"
                            label="押金">
                            <template slot-scope="scope">
                                {{ scope.row.deposit }}{{ scope.row.depositUnit }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="rent"
                            label="租金">
                            <template slot-scope="scope">
                                {{ scope.row.rent }}{{ scope.row.rentUnit }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="count"
                            label="数量">
                            <template slot-scope="scope">
                                {{ scope.row.count }}{{ scope.row.countUnit }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="form-group-title"><h2>当前使用房间记录</h2></div>
            <div class="select-room-box select-room-box-1">
                <template v-if="useStoreyList && useStoreyList.length > 0">
                    <div
                        v-for="storey in useStoreyList"
                        :key="storey.storey.id"
                        class="item clearfix">
                        <div class="st"
                    >{{ storey.building.name }}{{ storey.storey.name }}</div>
                        <ul>
                            <li
                                v-for="room in storey.roomList"
                                :key="room.id"
                            >{{ room.name }}</li>
                        </ul>
                    </div>
                </template>
                <div v-else class="empty">
                    暂无数据
                </div>
            </div> -->
            <el-row :gutter="20" class="submit-row">
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit" :loading="submitting" size="small">提交</el-button>
                        <el-button @click="handleResetForm" size="small">重置</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"
import verify from "@/utils/verify"
import BigDecimal from 'js-big-decimal'

export default {
    name: 'ApplyRoom',
    props: {
        params: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            pickerOptions: {
                disabledDate(time) {
                    let now = new Date();
                    let st = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
                    return time.getTime() < st.getTime();
                }
            },
            // propertyList: [
            //     { id: 1, name: '配置空调', },
            //     { id: 2, name: '配置架子床', },
            //     { id: 3, name: '配置柜子', },
            // ],
            storeyList: [],
            useStoreyList: [
                // {
                //     building: {
                //         id: 1,
                //         name: '1号楼',
                //     },
                //     storey: {
                //         id: 1,
                //         name: '2层',
                //     },
                //     roomList: [
                //         {
                //             id: 1,
                //             name: '201',
                //         },
                //         {
                //             id: 2,
                //             name: '202',
                //         }
                //     ],
                // },
            ],
            // property: {
            //     isIndeterminate: false,
            //     all: false,
            // },
            deposit: '-',
            useRoomCount: '-',
            canRoomCount: '-',
            tableData: [
                // {
                //     id: 1,
                //     name: '空调',
                //     deposit: '22.00',
                //     depositUnit: '元',
                //     rent: '51.00',
                //     rentUnit: '元/台/月',
                //     count: 10,
                //     countUnit: '台',
                // },
                // {
                //     id: 2,
                //     name: '柜子',
                //     deposit: '12.00',
                //     depositUnit: '元',
                //     rent: '31.00',
                //     rentUnit: '元/套/月',
                //     count: 4,
                //     countUnit: '套',
                // },
                // {
                //     id: 3,
                //     name: '宿舍',
                //     deposit: '12.00',
                //     depositUnit: '元',
                //     rent: '62.00',
                //     rentUnit: '元/间/月',
                //     count: 5,
                //     countUnit: '间',
                // },
            ],
            tableLoading: false,
            supplierList: [],
            costTypeList: [],
            form: {
                projectId: !this.params.projectId ? '' : this.params.id,
                supplierId: !this.params.id ? '' : this.params.id,
                roomIdList: [],
                // property: [],
                intoDate: '',
                outDate: '',
                chargingDay: '',
                costTypeId: '',
            },
            roomMap: {},
            rules: {
                supplierId: [{required: true, message: '请选择分包单位', trigger: 'change'}],
                costTypeId: [{required: true, message: '请选择费用类型', trigger: 'change'}],
                roomIdList: [
                    {required: true, message: '请选择房间', trigger: 'change'},
                    {required: true, validator: this.checkRoomCount, trigger: 'change'}
                ],
                intoDate: [{required: true, message: '请选择入住日期', trigger: 'change'}],
                chargingDay: [{required: true, message: '请选择开始计费日期', trigger: 'change'}],
                // property: [{required: true, message: '请选择资产', trigger: 'change'}],
            },
            submitting: false
        }
    },
    computed: {
        roomCount() {
            let count = 0;
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    storey.roomList.forEach(room => {
                        if (this.roomMap[room.id]) {
                            count++;
                        }
                    });
                }
            });
            return count;
        },
        roomDepositTotal() {
            if (this.form.costTypeId) {
                let cost = this.costTypeList.find(x => x.id == this.form.costTypeId);
                if (cost) {
                    let d = new BigDecimal(cost.deposit);
                    let c = new BigDecimal(this.roomCount);
                    return d.multiply(c).round(2, BigDecimal.RoundingModes.CEILING).getValue();
                } else {
                    return null;
                }
            }

            let total = new BigDecimal('0');
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    storey.roomList.forEach(room => {
                        if (this.roomMap[room.id]) {
                            total = total.add(new BigDecimal(room.deposit));
                        }
                    });
                }
            });
            return total.round(2, BigDecimal.RoundingModes.CEILING).getValue();
        },
        holdPersonCount() {
            let personCount = 0;
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    storey.roomList.forEach(room => {
                        if (this.roomMap[room.id]) {
                            personCount += room.checkinNum;
                        }
                    });
                }
            });
            return personCount;
        },
        depositTotal() {
            let total = 0;
            this.tableData.forEach(item => {
                total += item.deposit * item.count;
            });
            return total;
        },
        rentTotal() {
            let total = 0;
            this.tableData.forEach(item => {
                total += item.rent * item.count;
            });
            return total;
        },
    },
    watch: {
        roomMap: {
            immediate: true,
            deep: true,
            handler: function() {
                let list = [];
                this.storeyList.forEach(storey => {
                    if (storey.roomList) {
                        storey.roomList.forEach(room => {
                            if (this.roomMap[room.id]) {
                                list.push(room.id);
                            }
                        });
                    }
                });
                this.form.roomIdList = list;
                if (this.$refs.form) {
                    this.$refs.form.validateField("roomIdList");
                }
            }
        },
        deposit() {
            if (this.$refs.form && this.form.roomIdList && this.form.roomIdList.length > 0) {
                this.$refs.form.validateField("roomIdList");
            }
        },
        roomDepositTotal() {
            if (this.$refs.form && this.form.roomIdList && this.form.roomIdList.length > 0) {
                this.$refs.form.validateField("roomIdList");
            }
        },
    },
    created() {
        this.loadCostTypeList();
        if (!this.params.projectId) {
            this.loadSupperList();
            let org = this.getCurrentOrg();
            this.form.projectId = org.id;
        } else {
            this.loadData();
        }
    },
    methods: {
        ...common,
        ...verify,
        loadCostTypeList(){
            http.get('v1/buildGood/list', { params: { status: 1 } }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.costTypeList = req.data.data;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.submitting = false;
                this.httpCatch(err);
            });
        },
        loadSupperList() {
            http.get("v1/supplier/list").then(req => {
                if (req.data.code == http.SUCCESS) {
                    this.supplierList = req.data.data;
                }
                else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            });
        },
        loadData() {
            let params = {};
            params.supplierId = this.form.supplierId;

            http.get('v1/supplier/balanceInfo', { params: params }).then(req => {
                this.submitting = false;
                if (req.data.code == http.SUCCESS) {
                    this.deposit = req.data.data.deposit;
                    this.useRoomCount = req.data.data.useRoomCount;
                    this.canRoomCount = req.data.data.depositRoomCount;
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.submitting = false;
                this.httpCatch(err);
            });
        },
        handleSubmit(){
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    let data = {
                        projectId: this.form.projectId,
                        supplierId: this.form.supplierId,
                        buildRoomIds: this.form.roomIdList,
                        day: this.form.intoDate,
                        checkoutDay: this.form.outDate,
                        chargingDay: this.form.chargingDay,
                        costTypeId: this.form.costTypeId,
                    };
                    http.post('v1/build/supplierCheckin', data).then(req => {
                        this.submitting = false;
                        if (req.data.code == http.SUCCESS) {
                            this.showSuccess('申请成功', () => {
                                this.$emit('done');
                            });
                        } else {
                            this.showError(req.data.msg);
                        }
                    }).catch(err => {
                        this.submitting = false;
                        this.httpCatch(err);
                    });
                } else {
                    return false;
                }
            });
        },
        handleResetForm() {
            this.storeyList = [];
            this.roomMap = {};
            this.resetForm();
            this.$nextTick(() => {
                this.$refs.form.clearValidate("roomIdList");
            });
        },
        handleSelectRoom() {
            this.$emit('select-room', this.storeyList);
        },
        updateStoreyGroup(data) {
            this.storeyList = data;
            let map = {};
            this.storeyList.forEach(storey => {
                if (storey.roomList) {
                    storey.roomList.forEach(room => {
                        map[room.id] = true;
                        if (!(room.id in this.roomMap)) {
                            this.$set(this.roomMap, room.id, true);
                        }
                    });
                }
            });
            for (let id in this.roomMap) {
                if (!(id in map)) {
                    delete this.roomMap[id];
                }
            }
            this.roomMap = { ...this.roomMap };
        },

        handleRoomClick(room) {
            this.roomMap[room.id] = !this.roomMap[room.id];
        },
        handlePropertyAllChange(value) {
            if (value) {
                this.form.property = this.propertyList.map(x => x.id);
            } else {
                this.form.property = [];
            }
            this.property.isIndeterminate = false;
        },
        handlePropertyChange() {
            if (this.form.property.length == this.propertyList.length) {
                this.property.all = true;
                this.property.isIndeterminate = false;
            } else {
                if (this.form.property.length == 0) {
                    this.property.all = false;
                    this.property.isIndeterminate = false;
                } else {
                    this.property.isIndeterminate = true;
                }
            }
        },
        handleSelectSupplier() {
            this.loadData();
        },
        checkRoomCount(rule, value, callback) {
            if (this.deposit == '-') {
                callback(new Error('数据未初始化，请尝试重新选择分包或'));
                return;
            }

            if (this.roomDepositTotal === null) {
                callback(new Error('费用类型数据异常'));
                return;
            }

            let roomDepositTotal = new BigDecimal(this.roomDepositTotal);
            let deposit = new BigDecimal(this.deposit);
            if (roomDepositTotal.compareTo(deposit) > 0) {
                callback(new Error('押金余额不足'));
                return;
            }

            callback();
            
            // if (this.canRoomCount === null) {
            //     callback();
            //     return;
            // }
            // if (this.canRoomCount == '-') {
            //     callback(new Error('押金余额数据未初始化，请尝试重新选择分包'));
            // } else if (value.length > this.canRoomCount) {
            //     callback(new Error('押金余额不足'));
            // } else {
            //     callback();
            // }
        },
    }
};
</script>

<style scoped>
</style>
